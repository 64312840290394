/* .footer-section{
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #203040;
  color: white;
  height: auto; 
  bottom: 0;
  top: 100%;
}
.footer-info{
  display: flex;
  flex-direction: row;
  align-items:center;
  
}
.footer-details{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.footer-details-div{
  max-width: 300px;
}
.footer-details-div h1{
  font-size: 26px;
  margin-bottom: 0;
}
.copyright{
 width: 100%;
}
.copyright p{
  padding-left: 220px;
}

.footer-form{
  display: flex;
  flex-grow: 1;
 align-items: center;
 justify-content: center;
}
.footer-form-details{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-form-details input{
  width: 350px;
  height: 47px;
  border-radius: 10px;
  border-style: solid;
  border: none;
}
.footer-form-details button{
  width: 125px;
  height: 40px;
  color: white;
  border-radius: 500px;
  border-style: solid;
  border: none;
  background-color: black;
}
.footer-form-details button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media(max-width: 768px){
  .footer-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    gap: 100px;
  }
  .footer-form{
    justify-content: left;
    align-items: flex-start;
  }
  .footer-form-details{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
  }
  .copyright p{
    padding-left: 220px;
  }
  .footer-form-details input{
    width: 250px;
    height: 37px;
    border-radius: 10px;
    border-style: solid;
    border: none;
  }
} */

.footer-section {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  background-color: #203040;
  color: white;
  width: 100%;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  gap: 40px;
}

.footer-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer-details-div {
  max-width: 300px;
}

.footer-details-div h1 {
  font-size: 26px;
  margin-bottom: 16px;
  font-weight: 600;
}

.footer-details-div p {
  line-height: 1.5;
  margin: 0;
}

.footer-form {
  flex: 1;
  max-width: 400px;
}

.footer-form-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-form-details label {
  font-size: 14px;
  font-weight: 500;
}

.footer-form-details input {
  width: 100%;
  height: 47px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  color: white;
  padding: 0 16px;
  font-size: 16px;
  transition: all 0.2s ease;
}

.footer-form-details input:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-form-details input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.footer-form-details button {
  width: 125px;
  height: 40px;
  color: white;
  border-radius: 500px;
  border: none;
  background-color: black;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.footer-form-details button:hover:not(:disabled) {
  background-color: #333;
  transform: translateY(-1px);
}

.footer-form-details button:disabled {
  background-color: #4a4a4a;
  cursor: not-allowed;
  opacity: 0.7;
}

.copyright {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright p {
  margin: 0;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

/* Media Queries */
@media (max-width: 768px) {
  .footer-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
  }

  .footer-details {
    align-items: center;
  }

  .footer-form {
    width: 100%;
    max-width: 350px;
  }

  .footer-form-details {
    align-items: center;
  }

  .footer-form-details input {
    height: 40px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .footer-section {
    padding: 40px 16px;
  }

  .footer-details-div {
    max-width: 100%;
  }

  .footer-details-div h1 {
    font-size: 22px;
  }

  .footer-form-details input {
    height: 36px;
  }

  .footer-form-details button {
    width: 100%;
  }
}