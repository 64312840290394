/* Enhanced header.css */

.header-section {
  background-color: white;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  /* position: fixed; */
  width: 100vw;
  transition: all 0.3s ease;
  z-index: 1000;
}

.header-section.scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}

.header-section.visible {
  transform: translateY(0);
}

.header-section.hidden {
  transform: translateY(-100%);
}

.header-logo {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.logo {
  width: 320px;
  height: 115px;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.02);
}

.header-nav-section {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  padding-right: 20px;
}

.header-navlinks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 400px;
}

.nav-link {
  text-decoration: none;
  color: black;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  padding: 5px 0;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #333;
}

.link-underline {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: right;
}

.nav-link:hover .link-underline {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-link.active .link-underline {
  transform: scaleX(1);
}

.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 15px;
  position: relative;
  z-index: 1001;
}

.hamburger-line {
  display: block;
  width: 24px;
  height: 2px;
  background-color: #000;
  margin: 5px 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger.active .hamburger-line:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.hamburger.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .hamburger-line:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {
  .header-section {
    padding: 5px 0;
  }
  
  .logo {
    width: 215px;
    height: 78px;
  }

  .hamburger {
    display: block;
  }

  .header-navlinks {
    display: none;
  }

  .header-navlinks.open {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 90px;
    right: 0;
    width: 200px;
    background-color: white;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: auto;
    z-index: 1000;
    animation: slideIn 0.3s ease;
  }

  .nav-link {
    font-size: 16px;
    margin: 10px 0;
    opacity: 0;
    transform: translateX(20px);
    animation: fadeInRight 0.3s ease forwards;
  }

  .mobile-menu-overlay {
    display: block;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 460px) {
  .logo {
    width: 180px;
    height: 65px;
  }

  .header-navlinks.open {
    width: 100%;
    align-items: center;
  }
}

/* Add smooth transition for all interactive elements */
* {
  transition: all 0.3s ease;
}

/* Disable transitions for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}