.software-services-section{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 815px;
  background-image: url('../images/Home-1\ bg.svg');
  background-position: left 90px;
  background-color: #1a1b1c;
  background-size: 350px;
  background-repeat: no-repeat;
  color: white;
  font-family:"Open Sans", sans-serif;
  padding-top: 100px;
}

.software-services-section p{
  font-size: 16px;
  line-height: 1.2; 
  max-width: 600px;
  text-align: center;
  margin-top: 1px;
}

.software-services-section h1{
  font-size: 64px;
  line-height: 1.2;
  max-width: 800px;
  text-align: center;
  margin-top: 80px;
}

.software-services-section button{
  width: 194px;
  height: 56px;
  background-color: transparent;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  border-radius: 50px;
  color: white;
  font-size: 18px;
  margin-top: 50px;
  cursor: pointer;
}

.stickercard-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 70px;
}

.card{
  text-align: center;
  line-height: 1.2; /* Controls the space between lines */
  max-width: 390px;
  height: 170px;
  padding: 10px 15px;
  border: none;
  background-color: #282829;
  margin-left: 20px;
}

.quality-service-section{
  display: flex;
  flex-direction: column;
  background-color: #f5eddc;
  justify-content: space-around;
  align-items: center;
  padding: 100px 0px;
  font-family: "Open Sans", sans-serif;
}
.quality-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  
}
.quality-info h1{
  font-size: 48px;
  margin: 0;
}
.quality-info p{
  
  max-width: 600px;
  text-align: center;
  margin-bottom: 70px;
}

.quality-details-section{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
}
.quality-details-image img{
  width: 615px;
  height: 440px;
}

.quality-details{
  display: flex;
  flex-direction: column;
}
.quality-details-info{
  margin-bottom: 30px;
}
.quality-details-info h2{
  font-size: 18px;
  margin-bottom: 5px;
}
.quality-details-info p{
  max-width: 400px;
  margin: 0;
}

.aboutus-section{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #fcfbf5;
  padding: 70px 0px;
}

.aboutus-details{
  max-width: 350px;
  font-family: "Open Sans", sans-serif;
}
.aboutus-details h1{
  font-size: 48px;

}
.aboutus-details p{
  text-align: left;
}

.aboutus-image img{
  width: 400px;
  height: 465px;
}




@media(max-width:768px){
  .software-services-section{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 1515px;
    background: none;
    background-color: #1f1f20;
    color: white;
    font-family:"Open Sans", sans-serif;
    padding-top: 100px;
  }
  .software-services-section h1{
    font-size: 48px;
    line-height: 1.2;
    max-width: 600px;
    text-align: center;
    margin-top: 80px;
  }
  .software-services-section p{
    font-size: 14px;
    line-height: 1.2; 
    max-width: 400px;
    text-align: center;
    margin-top: 1px;
  }
  .software-services-section button{
    width: 174px;
    height: 46px;
    background-color: transparent;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    border-radius: 50px;
    color: white;
    font-size: 18px;
    margin-top: 50px;
  }
  .stickercard-section{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  .card{
    text-align: center;
    line-height: 1.2; /* Controls the space between lines */
    max-width: 390px;
    height: 170px;
    padding: 10px 15px;
    border: none;
    background-color: #282829;
  }
  .quality-service-section{
    display: flex;
    flex-direction: column;
    background-color: #f5eddc;
    justify-content: space-around;
    align-items: center;
    padding: 100px 0px;
    font-family: "Open Sans", sans-serif;
  }
  .quality-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    
  }
  .quality-info h1{
    font-size: 40px;
    margin: 0;
  }
  .quality-info p{
    max-width: 450px;
    text-align: center;
    margin-bottom: 70px;
  }
  .quality-details-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }
  .quality-details-image img{
    width: 415px;
    height: 340px;
  }
  
  .quality-details{
    display: flex;
    flex-direction: column;
  }
  .quality-details-info{
    margin-bottom: 30px;
    /* text-align: center; */
  }
  .quality-details-info h2{
    font-size: 18px;
    margin-bottom: 5px;
  }
  .quality-details-info p{
    max-width: 400px;
    margin: 0;
  }
  .aboutus-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    background-color: #fcfbf5;
    padding: 70px 0px;
  }
  
  .aboutus-details{
    max-width: 400px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
  }
  .aboutus-details h1{
    font-size: 40px;
  }
  .aboutus-details p{
    text-align: center;
  }
  .aboutus-image img{
    width: 380px;
    height: 415px;
  }

}

@media(max-width:460px){
  .software-services-section{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 1515px;
    background: none;
    background-color: #1f1f20;
    color: white;
    font-family:"Open Sans", sans-serif;
    padding-top: 100px;
  }
  .software-services-section h1{
    font-size: 40px;
    line-height: 1.2;
    max-width: 500px;
    text-align: center;
    margin-top: 80px;
  }
  .software-services-section p{
    font-size: 16px;
    line-height: 1.2; 
    max-width: 400px;
    text-align: center;
    margin-top: 1px;
  }
  .software-services-section button{
    width: 174px;
    height: 46px;
    background-color: transparent;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    border-radius: 50px;
    color: white;
    font-size: 18px;
    margin-top: 50px;
  }
  .stickercard-section{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }
  .card{
    text-align: center;
    line-height: 1.2; /* Controls the space between lines */
    max-width: 330px;
    height: 150px;
    padding: 10px 10px;
    border: none;
    background-color: #282829;
  }
  .quality-service-section{
    display: flex;
    flex-direction: column;
    background-color: #f5eddc;
    justify-content: space-around;
    align-items: center;
    padding: 100px 15px;
    font-family: "Open Sans", sans-serif;
  }
  .quality-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    text-align: center;
  }
  .quality-info h1{
    font-size: 40px;
    margin: 0;
  }
  .quality-info p{
    max-width: 450px;
    text-align: center;
    margin-bottom: 70px;
  }
  .quality-details-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }
  .quality-details-image img{
    width: 415px;
    height: 340px;
  }
  
  .quality-details{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .quality-details-info{
    margin-bottom: 30px;
    /* text-align: center; */
  }
  .quality-details-info h2{
    font-size: 18px;
    margin-bottom: 5px;
  }
  .quality-details-info p{
    max-width: 400px;
    margin: 0;
  }
  .aboutus-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
    background-color: #fcfbf5;
    padding: 70px 0px;
  }
  
  .aboutus-details{
    max-width: 400px;
    font-family: "Open Sans", sans-serif;
    text-align: center;
  }
  .aboutus-details h1{
    font-size: 40px;
  }
  .aboutus-details p{
    text-align: center;
  }
  .aboutus-image img{
    width: 380px;
    height: 415px;
  }

}

/* Add to your existing home.css */

/* Fade In Animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Hover Effects */
.hover-lift {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.hover-scale {
  transition: transform 0.3s ease;
}

.hover-scale:hover {
  transform: scale(1.05);
}

/* Enhanced Button Styles */
.Explore-btn {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.Explore-btn::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  background: rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease;
}

.Explore-btn:hover::after {
  transform: translateX(100%);
}

.Explore-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.05);
}

/* Enhanced Card Styles */
.card {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2), transparent);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.card:hover::before {
  transform: translateX(100%);
}

/* Enhanced Section Transitions */
.software-services-section,
.quality-service-section,
.aboutus-section {
  position: relative;
  overflow: hidden;
}

.quality-details-info {
  position: relative;
  padding: 20px;
  transition: all 0.3s ease;
}

.quality-details-info:hover {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

/* Responsive Animations */
@media (prefers-reduced-motion: reduce) {
  .fade-in,
  .hover-lift,
  .hover-scale,
  .Explore-btn,
  .card,
  .quality-details-info {
    transition: none;
    animation: none;
  }
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .fade-in {
    transform: translateY(10px);
  }
  
  .hover-lift:hover {
    transform: translateY(-3px);
  }
}